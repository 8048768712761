import { HeaderComponent } from 'components/header/component'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import React, { Component } from 'react'
import './_styles.scss'
import { getSystemStatus, noStatus, RasaSystemStatus } from '../../banner/component';
import { ToggleOn } from '../../icons/toggleon';
import { ToggleOff } from '../../icons/toggleoff';
import { Loading } from '../../loading';
import { AjaxWrapper, HttpMethod } from '../../../generic/ajaxWrapper';
import { DatePicker } from '@progress/kendo-react-dateinputs';

type OutageNotificationComponentProps = GenericRedux.AllComponentPropsWithModal<any>

interface OutageNotificationState extends RasaSystemStatus{
  isLoading: boolean,
  isSaving: boolean,
  selectedCommunity: string
}

class OutageNotificationComponent extends Component<OutageNotificationComponentProps, OutageNotificationState> {
  public static contextType = RasaContext

  constructor(props: OutageNotificationComponentProps) {
    super(props)
    this.state = {
      ...noStatus,
      isLoading: true,
      isSaving: false,
      selectedCommunity: '',
    }
  }

  public componentDidMount = () => {
    this.context.user.init().then(({activeCommunity}) => {
      this.setState({
        ...getSystemStatus(),
        selectedCommunity: activeCommunity.communityId,
      }, () => {
        this.getNotification()
      })
    })
  }

  public render() {
    return <div className="app-notification-wrapper">
      <div className="header-container">
        <HeaderComponent
          title={'Outage Notification'}
          subTitle={'Outage Notification'}
          description={[
            'Handle Outage Notification.',
          ]}/>
      </div>
      {
        this.state.isSaving || this.state.isLoading
          ? <Loading size="64"/>
          :
          <div className="on-form-wrap">
            <div className="on-form-row">
              <textarea
                onChange={(e) => this.setState({message: e.currentTarget.value})}
                className="form-control"
                rows={4}
              >
                {this.state.message}
                </textarea>
            </div>
            <div className='on-form-row child-input-wrapper'>
              <label className="">Publish Date</label>
              <DatePicker
                className="end-date-picker"
                format="yyyy-MM-dd"
                value={this.state.timestamp}
                onChange={(e) => this.setState({timestamp: e.value})} ></DatePicker>
            </div>
            <div className="on-form-row">
              <label className="">Schedule</label>
              <div onClick={(e) => this.setState({isActive: !this.state.isActive})} className="toggle-active">
                <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
                  {this.state.isActive ? <ToggleOn/> : <ToggleOff/>}
                </button>
              </div>
            </div>
            <div className="on-form-row">
              <label className="">Publish</label>
              <div onClick={(e) => this.setState({isPublish: !this.state.isPublish})} className="toggle-active">
                <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
                  {this.state.isPublish ? <ToggleOn/> : <ToggleOff/>}
                </button>
              </div>
            </div>
            <div className="on-form-row on-btn-wrap">
              <button type="button" className="add-button btn btn-secondary" onClick={(e) => this.updateNotification()}>
                <span className="button-text">Save</span>
              </button>
            </div>
          </div>
      }
    </div>
  }

  private getNotification = () => {
    this.setState({
      isLoading: true,
    })
    const url: string = `${AjaxWrapper.getServerUrl()}/cs/${this.state.selectedCommunity}/outage-notification`
    return AjaxWrapper.ajax(url, HttpMethod.GET, null)
      .then((res: {content: RasaSystemStatus}) => {
        if (res.content.timestamp) {
          res.content.timestamp = new Date(res.content.timestamp)
        }
        this.setState({
          isLoading: false,
          ...res.content
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        })
      })
  }

   private updateNotification = () => {
     this.setState({
       isSaving: true,
     })
     const url: string = `${AjaxWrapper.getServerUrl()}/cs/${this.state.selectedCommunity}/outage-notification`
     return AjaxWrapper.ajax(url, HttpMethod.PUT, {
       message: this.state.message,
       isActive: this.state.isActive,
       isPublish: this.state.isPublish,
       timestamp: this.state.timestamp,
     })
       .then((res: any) => {
         this.setState({
           isSaving: false,
         })
       })
       .catch((error) => {
         this.setState({
           isSaving: false,
         })
       })
   }
}


export const OutageNotification = GenericRedux.registerNewComponentWithModals<any>(
  OutageNotificationComponent,
  'outage_notification',
  []
  )
